import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, take, filter } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { UNAUTHORIZED_ERROR_CODE } from '@constants/meta-data';
import { LoaderService } from '@shared/loader/loader.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(this.addToken(req)).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === UNAUTHORIZED_ERROR_CODE
        ) {
          return this.handle401Error(req, next);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private addToken(req: HttpRequest<any>): HttpRequest<any> {
    const token = this.authService.getToken();

    if (token) {
      return req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return req;
  }

  private handle401Error(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      return this.authService.refreshToken().pipe(
        switchMap((newToken: string) => {
          this.isRefreshing = false;
          return next.handle(this.addToken(req)); // Retry the original request with the new token
        }),
        catchError((err) => {
          this.loaderService.isLoading.next(false);
          this.isRefreshing = false;
          this.authService.logout(); // Logout if refresh token fails
          return throwError(() => err);
        })
      );
    } else {
      return next.handle(this.addToken(req)); // Retry the queued request
    }
  }
}
